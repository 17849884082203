import { SegmentTypeEnum } from 'main/modules/segment/enums/SegmentTypeEnum'
import { PersonUtils } from 'main/modules/people/utils/PersonUtils'
import React, { useEffect, useState } from 'react'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { useRequest } from 'main/common/request-manager/use-request/UseRequest'
import { PersonRequests } from 'main/modules/people/services/person/PersonRequests'
import { RequestUtils } from 'main/common/request-manager/RequestUtils'
import { PersonMainInfoBarCP } from 'main/modules/people/components/person-main-info-bar/PersonMainInfoBarCP'
import { styled } from 'main/config/theme/styledWithTheme'
import { FlexCP } from 'main/common/components/flex/FlexCP'
import { TabsCP } from 'main/common/components/tabs/TabsCP'
import { PersonDetailsDrawerUtils } from 'main/modules/people/components/drawer-person-details/inner/PersonDetailsDrawerUtils'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { TabItemTP } from 'main/common/components/tabs/inner/TabItemTP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'

interface IPersonDetailsDrawerCPProps {
    visible: boolean
    onClose: () => void
    onSave: () => void
    personCode?: number
    segmentType: SegmentTypeEnum
}

/**
 * Drawer de cadastro e detalhes pessoa/usuário
 */
export function DrawerPersonDetailsCP(props: IPersonDetailsDrawerCPProps): JSX.Element {

    useEffect(init, [props.visible])

    const [person, setPerson] = useState<IPersonResponseDTO>()
    const getPersonRequest = useRequest<IPersonResponseDTO>()
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

    /**
     * Inicializa.
     */
    function init(): void {

        if (!props.personCode)
            return

        setPerson(undefined)
        getPersonRequest.runRequest(PersonRequests.getOne(props.personCode))
    }

    /**
     * Retorno da API com os dados da pessoa.
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Erro ao buscar detalhes do contato.'))
            return

        setPerson(getPersonRequest.responseData)
    }

    /**
     * Obtem as abas para cada tipo de pessoa.
     */
    function getTabsConfig(): TabItemTP[] {

        if (!person)
            return []

        const personNameAndCode: INameAndCodeResponseDTO = {
            code: person.code,
            name: person.name
        }

        switch (props.segmentType) {
            case SegmentTypeEnum.CUSTOMER:
                return PersonDetailsDrawerUtils.geCustomerTabs(person)

            case SegmentTypeEnum.COLLABORATOR:
                return PersonDetailsDrawerUtils.geUserTabs(personNameAndCode)

            case SegmentTypeEnum.SUPPLIER:
            default:
                return []
        }

    }

    return (
        <DrawerCP
            title={`Dados do ${PersonUtils.getSegmentTypeLabel(props.segmentType)}`}
            width={props.segmentType === SegmentTypeEnum.SUPPLIER ? 400 : '83vw'}
            visible={props.visible}
            onClose={props.onClose}
            loading={getPersonRequest.isAwaiting}
        >
            {
                person &&
                <FlexCP justifyContent={'space-between'}>

                    <LeftSideSCP>
                        <PersonMainInfoBarCP
                            person={person}
                            segmentType={props.segmentType}
                            onUpdate={init}
                        />
                    </LeftSideSCP>

                    <RightSideSCP>
                        <TabsCP
                            tabPosition={'top'}
                            type={'line'}
                            tabs={getTabsConfig()}
                            
                        />
                    </RightSideSCP>

                </FlexCP>
            }

        </DrawerCP>
    )
}

const LeftSideSCP = styled.div`
    padding-right: 10px;
    width: 320px;
`

const RightSideSCP = styled.div`
    border-left: dashed 1px #dedede;
    flex-grow: 1;
    padding: 0 4px;
    overflow: hidden;
`

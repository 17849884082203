//@ts-nocheck
import React, { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'
import styled from 'main/config/theme/styledWithTheme'
import { LINE_CHART_CONFIG_OPTIONS } from 'main/common/components/chart/inner/LineChartConfigOptions'
import { BAR_CHART_CONFIG_OPTIONS } from 'main/common/components/chart/inner/BarChartConfigOptions'

export type ChartSeriesTP = {
    data: number[],
    name: string,
}

interface IScpProps {
    height?: string
}

interface IChartLineOrBarCPProps extends IScpProps{
    xAxis: string[]
    ySeries: ChartSeriesTP[]
    type: 'line' | 'bar' | 'paulo'
    titulo?: string
    tipoData?: string
}

/**
 * COMPONENTE Grafico de Linha ou Barra.
 */
export function ChartLineOrBarCP(props: IChartLineOrBarCPProps): JSX.Element {

    const chartRef = useRef<any>(null)
    const chartElement = useRef<any>(null)

    useEffect(onMount, [])
    useEffect(updateChartData, [props.ySeries, props.xAxis])

    function onMount(): void {
        if(props.type === "paulo"){
            chartElement.current = new ApexCharts(chartRef.current, retornarConfig(props.titulo, props.tipoData));
            chartElement.current.render()
            return
        }
            chartElement.current = new ApexCharts(chartRef.current, props.type === 'line' ? LINE_CHART_CONFIG_OPTIONS.getConfig(props.height) : BAR_CHART_CONFIG_OPTIONS)
            chartElement.current.render()
        
    }

    function retornarConfig(titulo:any, tipoDado:any){
        return {
            series: [{
            name: 'Inflation',
            data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
        }],
            chart: {
                toolbar: {
                    show: false
                  },
            height: 200,
            type: 'bar',
        },
        
        plotOptions: {
            bar: {
            borderRadius: 10,
            dataLabels: {
                position: 'top', // top, center, bottom
            },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val:any) {
            return val + `${tipoDado}`;
            },
            offsetY: -20,
            style: {
            fontSize: '12px',
            colors: ["#304758"]
            }
        },
        
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            position: 'top',
            axisBorder: {
            show: false
            },
            axisTicks: {
            show: false
            },
            crosshairs: {
            fill: {
                type: 'gradient',
                gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
                }
            }
            },
            tooltip: {
            enabled: true,
            }
        },
        yaxis: {
            axisBorder: {
            show: false
            },
            axisTicks: {
            show: false,
            },
            labels: {
            show: false,
            formatter: function (val:any) {
                return val + `${tipoDado}`;
            }
            }
        
        },
        title: {
            text: titulo,
            floating: true,
            offsetY: 180,
            align: 'center',
            style: {
            color: '#444'
            }
        }
      }
    
    }

    

    function updateChartData(): void {

        if (!props.ySeries || !chartElement.current)
            return

        chartElement.current.updateSeries(props.ySeries)
        chartElement.current.updateOptions({
            xaxis: { categories: props.xAxis }
        })
    }

    return (
        <ChartWrapperSCP>
            <div ref={chartRef}/>
        </ChartWrapperSCP>
    )
}

const ChartWrapperSCP = styled.div`
    height: 100%;
    width: 100%;
`

import React from 'react'
import { ListPersonPurchaseCP } from 'main/modules/people/components/list-person-purchase/ListPersonPurchaseCP'
import { UserPermissionCP } from 'main/modules/user/components/user-permission/UserPermissionCP'
import { UserWorkScheduleCP } from 'main/modules/user/components/user-work-schedule/UserWorkScheduleCP'
import { INameAndCodeResponseDTO } from 'main/common/dtos/responses/INameAndCodeResponseDTO'
import { TimelinePersonContactsCP } from 'main/modules/people/components/timeline-person-contacts/TimelinePersonContactsCP'
import { PersonContactEntityFilterEnum } from 'main/modules/people/services/person-contact/enums/PersonContactEntityFilterEnum'
import { ListPersonOpportunitiesCP } from 'main/modules/people/components/list-person-opportunities/ListPersonOpportunitiesCP'
import { IPersonResponseDTO } from 'main/modules/people/services/person/dtos/response/IPersonResponseDTO'
import { ListPersonReferralsCP } from 'main/modules/people/components/list-person-referrals/ListPersonReferralsCP'
import { TabItemTP } from 'main/common/components/tabs/inner/TabItemTP'
import { RegistroComissaoParticular } from 'main/modules/products/components/product-release-comissoes/RegistroComissaoParticular'
// import { AnamnesesPerson } from '../../grupo-anamneses/AnamnesesPerson'
import { CollapseSessionFormHistoryCP } from 'main/modules/scheduler/components/collapse-session-form-history/CollapseSessionFormHistoryCP'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { PermissionEnum } from 'submodules/neritclin-sdk/services/user/enums/PermissionEnum'
import { Bioimpedancia } from '../../bioimpedancia/Bioimpedancia'
/**
 * Utils do drawer de pessoas
 */
export const PersonDetailsDrawerUtils = {

    geCustomerTabs(person: IPersonResponseDTO): TabItemTP[] {

        return [
            {
                title: 'Compras',
                key: 'purchases',
                content: <ListPersonPurchaseCP person={{ code: person.code, name: person.name }}/>
            },
            {
                title: 'Oportunidades',
                key: 'opportunities',
                content: <ListPersonOpportunitiesCP person={person}/>
            },
            {
                title: 'Interações',
                key: 'interactions',
                content: <TimelinePersonContactsCP entityCode={person.code} entityFilter={PersonContactEntityFilterEnum.ALL} showTitle={false}/>
            },
            {
                title: 'Bioimpedância',
                key: 'bio',
                content: <Bioimpedancia person={person}></Bioimpedancia>
            },
            {
                title: 'Anamneses',
                key: 'anamneses',
                content: (

                    <CollapseSessionFormHistoryCP personCode={person.code}/>
                )
            },
            {
                title: 'Indicações',
                key: 'referral',
                content: <ListPersonReferralsCP person={person}/>
            },
        ]

    },

    geUserTabs(user: INameAndCodeResponseDTO): TabItemTP[] {
        const somenteHorarios = !!AppStateUtils.getCurrentFranchise()?.permissions?.includes(PermissionEnum.ROLE_HORARIO_TRABALHO);
        return [
            {
                title: 'Permissões de usuário',
                key: 'userPermissions',
                hide:somenteHorarios,
                content: <UserPermissionCP userCode={user.code}/>,
            },
            {
                title: 'Horário de trabalho',
                key: 'workSchedule',
                content: <UserWorkScheduleCP userCode={user.code}/>
            },
            {
                title: 'Comissões',
                key: 'comis',
                hide:somenteHorarios,
                content: <RegistroComissaoParticular idPessoa={user.code}/>
            },
        ]
    },

}

import { IsValidCnpj } from 'main/common/validation/decorators/IsValidCnpj'
import { IsOptional, IsEmail } from 'class-validator/decorator/decorators'
import { GenericFormValidator } from 'main/common/form-state-manager/GenericFormValidator'
import { IsString } from 'main/common/validation/decorators/IsString'
import { IsNotEmpty } from 'main/common/validation/decorators/IsNotEmpty'
import { IsPhoneBR } from 'main/common/validation/decorators/phone/IsPhoneBR'

/**
 * VALIDADOR
 * Define validacao dos campos do formulario de para editar dados da empresa
 *
 * @author Rafael V.
 */
export class FranchiseFormModel extends GenericFormValidator {

    @IsNotEmpty()
    @IsValidCnpj()
    cnpj: string

    @IsNotEmpty()
    @IsString()
    socialName: string

    @IsOptional()
    @IsString()
    fantasyName: string

    @IsOptional()
    @IsString()
    stateRegistration: string

    @IsOptional()
    @IsEmail()
    email: string

    @IsOptional()
    @IsPhoneBR()
    phone: string

    @IsOptional()
    @IsString()
    zipCode: string

    @IsOptional()
    @IsString()
    street: string

    @IsOptional()
    number: string

    @IsOptional()
    @IsString()
    complement: string

    @IsOptional()
    @IsString()
    neighborhood: string

    @IsOptional()
    @IsString()
    city: string

    @IsOptional()
    @IsString()
    state: string

    @IsOptional()
    ocultarProcedimentos: boolean

    constructor(initialData?: Partial<FranchiseFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}

import { DrawerAppointmentSessionDetailsCP } from 'main/modules/scheduler/components/drawer-appointment-session-details/DrawerAppointmentSessionDetailsCP'
import { IAppointmentDetails } from 'main/modules/scheduler/interfaces/IAppointmentDetails'
import React, { useEffect, useState } from 'react'
import { DateFormatEnum } from 'main/common/enums/DateFormatEnum'
import { CalendarEventsInnerICP } from 'main/modules/scheduler/components/calendar-events/inner/CalendarEventsInnerICP'
import { IUserAttendancesResponseDTO } from 'main/modules/scheduler/services/scheduler/dtos/response/IUserAttendancesResponseDTO'
import { DateUtils } from 'main/common/utils/date/DateUtils'
import { DrawerScheduleSessionCP } from 'main/modules/scheduler/components/drawer-schedule-session/DrawerScheduleSessionCP'
import { IScheduleSlotInitialData } from 'main/modules/scheduler/components/drawer-schedule-session/inner/IScheduleSlotInitialData'
import { OpportunityEvaluationRequests } from 'main/modules/sales-funnel/services/evaluation/OpportunityEvaluationRequests'
import { IOpportunityEvaluationScheduleSearchRequestDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/request/IOpportunityEvaluationScheduleSearchRequestDTO'
import { IOpportunityEvaluationScheduleResponseDTO } from 'main/modules/sales-funnel/services/evaluation/dtos/response/IOpportunityEvaluationScheduleResponseDTO'
import { IGenericListResponseDTO } from 'main/common/dtos/responses/IGenericListResponseDTO'
import { DrawerEvaluationDataCP } from 'main/modules/sales-funnel/components/drawer-evaluation-data/DrawerEvaluationDataCP'
import { BlockIntervalModalCP } from 'main/modules/scheduler/components/block-interval-modal/BlockIntervalModalCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormFilterCalendarEventsFormModel } from 'main/modules/scheduler/components/form-filter-calendar-events/inner/FormFilterCalendarEventsFormModel'
import { SearchScheduleAppointmentsRequestDTO } from 'submodules/neritclin-sdk/services/schedule/dtos/requests/SearchScheduleAppointmentsRequestDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ScheduleRequests } from 'submodules/neritclin-sdk/services/schedule/ScheduleRequests'
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { SystemConfig } from 'main/config/SystemConfig'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'
import { NotificationHelper } from 'main/common/helpers/NotificationHelper'

interface ISchedulerScreenContentCPProps {
    filtersFormStateManager: IFormStateManager<FormFilterCalendarEventsFormModel>
    shouldLoadEvents: number
}

/**
 * Conteúdo da tela do módulo de agenda
 */
export function CalendarEventsCP(props: ISchedulerScreenContentCPProps): JSX.Element {

    const [mustOpenAppointmentDrawer, setOpenAppointmentDrawer] = useState<boolean>(false)
    const [isScheduleSessionDrawerVisible, setIsScheduleSessionDrawerVisible] = useState<boolean>(false)
    const [isEvaluationDrawerVisible, setIsEvaluationDrawerVisible] = useState<boolean>(false)
    const [isBlockModalVisible, setIsBlockModalVisible] = useState<boolean>(false)

    const [appointmentDetailsData, setAppointmentDetailsData] = useState<IAppointmentDetails>()
    const [selectedEvaluationCode, setSelectedEvaluationCode] = useState<number>()
    const [scheduleSlotInitialData, setScheduleSlotInitialData] = useState<IScheduleSlotInitialData>()

    const [userAttendances, setUserAttendances] = useState<IUserAttendancesResponseDTO[]>([])
    const getUserAttendancesRequest = useRequest<IUserAttendancesResponseDTO[]>()
    useEffect(onGetUserAttendancesRequestChange, [getUserAttendancesRequest.isAwaiting])

    const [evaluations, setEvaluations] = useState<IOpportunityEvaluationScheduleResponseDTO[]>([])
    const searchEvaluationsRequest = useRequest<IGenericListResponseDTO<IOpportunityEvaluationScheduleResponseDTO>>()
    useEffect(onSearchEvaluationsRequestChange, [searchEvaluationsRequest.isAwaiting])

    useEffect(init, [props.shouldLoadEvents])

    /**
     * Inicializa informacoes.
     */
    function init(): void {

        if (!props.shouldLoadEvents)
            return

        const formValues = props.filtersFormStateManager.getFormValues()
        if (!formValues || !formValues.dateInterval)
            return

        const searchDto: SearchScheduleAppointmentsRequestDTO = {
            dateRange: formValues.dateInterval,
            userCodes: formValues.userCodes,
            treatmentGroupCodes: formValues.treatmentGroupCodes,
            showNoShow: formValues.showNoShow
        }
        getUserAttendancesRequest.runRequest(ScheduleRequests.search(searchDto))

        // Carrega as avaliacoes
        showOrHideEvaluations()
    }

    /**
     * Retorno ao buscar agendamentos.
     */
    function onGetUserAttendancesRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getUserAttendancesRequest, 'Erro ao buscar agendamentos.'))
            return

        setUserAttendances(getUserAttendancesRequest.responseData!)
    }

    /**
     * Define detalhes para mostrar ou esconder as avaliacoes
     */
    function showOrHideEvaluations(): void {

        const formValues = props.filtersFormStateManager.getFormValues()
        if (!formValues?.showEvaluations)
            return setEvaluations([])

        const filters: IOpportunityEvaluationScheduleSearchRequestDTO = {
            userResponsableCodes: formValues.userCodes,
            beginDate: DateUtils.getFormatted(formValues.dateInterval.beginDate, DateFormatEnum.US_WITHOUT_TIME),
            endDate: DateUtils.getFormatted(formValues.dateInterval.endDate, DateFormatEnum.US_WITHOUT_TIME),
            itemsPerPage: 999,
            page: 1,
        }
        searchEvaluationsRequest.runRequest(OpportunityEvaluationRequests.searchAllSchedulesConfig(filters))
    }

    async function testarSeSessaoExiste(codigos:any){
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/sessions/checar-existencia`,
            method: HttpMethodEnum.POST,
            params: {
                codigos:[codigos]
            }

           
        }).finally(()=>{}); 
        console.log("Resultado ", resultado);
        const res = resultado?.data?.data=='positivo';
        if(!res){
            setTimeout(()=>{
                setOpenAppointmentDrawer(false);
                init();
                NotificationHelper.error('Opa!','Esta sessão já foi encerrada!');
            },230);
        }
    }

    /**
     * Retorno ao buscar avaliacoes.
     */
    function onSearchEvaluationsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchEvaluationsRequest, 'Erro ao buscar agendamentos de avaliacao'))
            return

        setEvaluations(searchEvaluationsRequest.responseData?.list ?? [])
    }

    return (
        <>
            <CalendarEventsInnerICP
                filtersFormStateManager={props.filtersFormStateManager}
                userAttendances={userAttendances}
                evaluations={evaluations}
                shouldLoadSummary={props.shouldLoadEvents}
                onSelectDateToSchedule={(data) => {
                    setIsScheduleSessionDrawerVisible(true)
                    setScheduleSlotInitialData(data)
                }}
                onSelectSessions={(_appointmentDetailsData) => {
                    console.log("Dados da Sessao ", _appointmentDetailsData);
                    testarSeSessaoExiste(_appointmentDetailsData.sessionCodes);
                    setAppointmentDetailsData(_appointmentDetailsData)
                    setOpenAppointmentDrawer(true)
                }}
                reloadEvents={() => {
                    // Define como vazio primeiro para atualizar agenda, somente atualizar o conteudo nao estava atualizando a tela
                    setUserAttendances([])
                    init()
                }}
                onSelectEvaluation={(evaluationCode) => {
                    setSelectedEvaluationCode(evaluationCode)
                    setIsEvaluationDrawerVisible(true)
                }}
                onSelectBlockInterval={(initialData) => {
                    setScheduleSlotInitialData(initialData)
                    setIsBlockModalVisible(true)
                }}
                loading={getUserAttendancesRequest.isAwaiting || searchEvaluationsRequest.isAwaiting}
            />

            {
                appointmentDetailsData &&
                <DrawerAppointmentSessionDetailsCP
                    visible={mustOpenAppointmentDrawer}
                    appointmentDetailsData={appointmentDetailsData}
                    onClose={(wasDataChanged) => {
                        setAppointmentDetailsData(undefined)
                        setOpenAppointmentDrawer(false)

                        if (wasDataChanged)
                            init()
                    }}
                />
            }

            {
                scheduleSlotInitialData &&
                <DrawerScheduleSessionCP
                    visible={isScheduleSessionDrawerVisible}
                    scheduleSessionInitialData={scheduleSlotInitialData}
                    showBlockIntervalButton={true}
                    onClose={() => {
                        setIsScheduleSessionDrawerVisible(false)
                        init()
                    }}
                />
            }

            <DrawerEvaluationDataCP
                visible={isEvaluationDrawerVisible}
                onClose={() => setIsEvaluationDrawerVisible(false)}
                evaluationScheduleCode={selectedEvaluationCode}
                onSave={() => {
                    setIsEvaluationDrawerVisible(false)
                    showOrHideEvaluations()
                }}
            />

            {
                !!scheduleSlotInitialData && scheduleSlotInitialData.userProfessional &&
                <BlockIntervalModalCP
                    visible={isBlockModalVisible}
                    onClose={() => setIsBlockModalVisible(false)}
                    onActionDone={() => {
                        setIsBlockModalVisible(false)
                        setScheduleSlotInitialData(undefined)
                        init()
                    }}
                    userProfessionalCode={scheduleSlotInitialData.userProfessional.code}
                    userAttendanceScheduleCode={scheduleSlotInitialData.userAttendanceScheduleCode}
                    initialTime={scheduleSlotInitialData.initialTime}
                />
            }
        </>
    )
}


import React, { useState, useRef } from 'react';
import { IPersonResponseDTO } from '../../services/person/dtos/response/IPersonResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils';
import { ChartLineOrBarCP } from 'main/common/components/chart/ChartLineOrBarCP';
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum';
import { CardCP } from 'main/common/components/card/CardCP';
import { TabsCP } from 'main/common/components/tabs/TabsCP';
import { ChartPieCP } from 'main/common/components/chart/ChartPieCP';
import styled from 'styled-components';
import { StatisticCardCP } from 'main/common/components/statistic-card/StatisticCardCP';
import { FontAwsomeIconCP } from 'main/common/components/icons/FontAwsomeIconCP';
import { ThemeAnt } from 'config/theme/ant/ThemeAnt';
import { faBalanceScale, faBirthdayCake, faCalendar, faCaretSquareUp, faInfoCircle,  faMedkit, faTimes,  faUserMd } from '@fortawesome/free-solid-svg-icons';
import { UtilGeral } from '../../utils/UtilGeral';
import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { SystemConfig } from 'main/config/SystemConfig';
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import html2canvas from 'html2canvas';

interface DashBioInterface{
    pessoa:IPersonResponseDTO,
    registros?:any
}
//@ts-ignore
let series:PieChartSeriesTP[] = [];
export function Dashbio(props: DashBioInterface): JSX.Element {
    const[base,setBase] = useState<any>(null);
    const [carregando,setCarregando] = useState<any>(null);
    const divRef = useRef(null);
    const tabelaRef = useRef(null);
    const tabelaRef2 = useRef(null);
    if(!props.registros || !props.registros.length){
        return(
        <AvisoVazio>
            <div>
                <div>
                    <FontAwsomeIconCP type={faTimes} size={'3x'}/>
                </div>
                <div>
                    Nenhum registro para exibir
                </div>
            </div>
        </AvisoVazio>
        );
    }



async function handleCapture(elementRef:any) {
  try {
    // Usa o html2canvas para capturar o conteúdo do elemento
    const canvas = await html2canvas(elementRef, {
      useCORS: true,
      allowTaint: false
    });
    
    // Converte o canvas para uma imagem base64
    const base64Image = canvas.toDataURL('image/png',0.8);
    return base64Image;
  } catch (error) {
    console.error('Erro ao capturar o elemento:', error);
    throw error;
  }
}







       
    
    //   //@ts-ignore
    //   const loadImage = (url, width, height) => {
    //     return new Promise((resolve, reject) => {
    //       const img = new Image();
    //       img.onload = () => {
    //         const canvas = document.createElement('canvas');
    //         canvas.width = width;
    //         canvas.height = height;
    //         const context = canvas.getContext('2d');
    //         //@ts-ignore
    //         context.drawImage(img, 0, 0);
    //         const dataUrl = canvas.toDataURL('image/png');
    //         resolve(dataUrl);
    //       };
    //       img.onerror = (error) => {
    //         reject(error);
    //       };
    //       img.src = url;
    //     });
    //   }

    function substituir(texto: any, parametro: string, valor: any): string {
        const regex = new RegExp(':' + parametro, 'g');
        return texto.replace(regex, valor);
    }
      

    const propriedade = {width:'100%'};
    calcularFicha(props.registros?props.registros[0]:null);
    function calcularFicha(bas:any){
        if(!bas || bas['status_visceral'])return;
        bas.status_imc = 'Inferior ao Normal';
        bas.indice_massa_corporal = parseFloat(bas.indice_massa_corporal);
        if(bas.indice_massa_corporal >= 18.5 && bas.indice_massa_corporal < 25 ) bas.status_imc = 'Normal';
        if(bas.indice_massa_corporal >= 25 && bas.indice_massa_corporal < 30 ) bas.status_imc = 'Sobrepeso';
        if(bas.indice_massa_corporal >= 30 ) bas.status_imc = 'Obesidade';
        bas.idade = parseInt(UtilGeral.DEVOLVER_SO_NUMEROS(DateUtils.ageCalculate(new Date(props.pessoa.birthDate+''))));
        bas.sexo = props.pessoa.gender=='MALE'?'h':'m';
        bas.status_gordura_corporal = 'Normal';
        bas.status_visceral = 'Ideal';
        bas.status_esqueletica = 'Normal';//calculando esqueletica
        bas.percentual_gordura_corporal = parseFloat(bas.percentual_gordura_corporal);
        bas.massa_muscular_esqueletica = parseFloat(bas.massa_muscular_esqueletica);
        bas.gordura_visceral = parseFloat(bas.gordura_visceral);
        if(bas.sexo=='m'){
            if(bas.idade>=20 && bas.idade<=39){
                if(bas.percentual_gordura_corporal<21)bas.status_gordura_corporal = 'Baixo';
                if(bas.percentual_gordura_corporal>=21 && bas.percentual_gordura_corporal<33)bas.status_gordura_corporal = 'Normal';
                if(bas.percentual_gordura_corporal>=33 && bas.percentual_gordura_corporal<39)bas.status_gordura_corporal = 'Alto';
                if(bas.percentual_gordura_corporal>=39 )bas.status_gordura_corporal = 'Muito Alto';
            }
            if(bas.idade>=40 && bas.idade<=59){
                if(bas.percentual_gordura_corporal<23)bas.status_gordura_corporal = 'Baixo';
                if(bas.percentual_gordura_corporal>=23 && bas.percentual_gordura_corporal<34)bas.status_gordura_corporal = 'Normal';
                if(bas.percentual_gordura_corporal>=34 && bas.percentual_gordura_corporal<40)bas.status_gordura_corporal = 'Alto';
                if(bas.percentual_gordura_corporal>=40 )bas.status_gordura_corporal = 'Muito Alto';
            }
            if(bas.idade>=60 && bas.idade<=79){
                if(bas.percentual_gordura_corporal<24)bas.status_gordura_corporal = 'Baixo';
                if(bas.percentual_gordura_corporal>=24 && bas.percentual_gordura_corporal<36)bas.status_gordura_corporal = 'Normal';
                if(bas.percentual_gordura_corporal>=36 && bas.percentual_gordura_corporal<42)bas.status_gordura_corporal = 'Alto';
                if(bas.percentual_gordura_corporal>=42 )bas.status_gordura_corporal = 'Muito Alto';
            }
        }

        if(bas.sexo=='h'){
            if(bas.idade>=20 && bas.idade<=39){
                if(bas.percentual_gordura_corporal<8)bas.status_gordura_corporal = 'Baixo';
                if(bas.percentual_gordura_corporal>=8 && bas.percentual_gordura_corporal<20)bas.status_gordura_corporal = 'Normal';
                if(bas.percentual_gordura_corporal>=20 && bas.percentual_gordura_corporal<25)bas.status_gordura_corporal = 'Alto';
                if(bas.percentual_gordura_corporal>=25 )bas.status_gordura_corporal = 'Muito Alto';
            }
            if(bas.idade>=40 && bas.idade<=59){
                if(bas.percentual_gordura_corporal<11)bas.status_gordura_corporal = 'Baixo';
                if(bas.percentual_gordura_corporal>=11 && bas.percentual_gordura_corporal<22)bas.status_gordura_corporal = 'Normal';
                if(bas.percentual_gordura_corporal>=22 && bas.percentual_gordura_corporal<28)bas.status_gordura_corporal = 'Alto';
                if(bas.percentual_gordura_corporal>=28 )bas.status_gordura_corporal = 'Muito Alto';
            }
            if(bas.idade>=60 && bas.idade<=79){
                if(bas.percentual_gordura_corporal<13)bas.status_gordura_corporal = 'Baixo';
                if(bas.percentual_gordura_corporal>=13 && bas.percentual_gordura_corporal<25)bas.status_gordura_corporal = 'Normal';
                if(bas.percentual_gordura_corporal>=25 && bas.percentual_gordura_corporal<30)bas.status_gordura_corporal = 'Alto';
                if(bas.percentual_gordura_corporal>=30 )bas.status_gordura_corporal = 'Muito Alto';
            }
        }

       
        if(bas.sexo=='m'){
            if(bas.idade>=18 && bas.idade<=39){
                if(bas.massa_muscular_esqueletica<24.3)bas.status_esqueletica = 'Baixo';
                if(bas.massa_muscular_esqueletica>=24.3 && bas.massa_muscular_esqueletica<30.4)bas.status_esqueletica = 'Normal';
                if(bas.massa_muscular_esqueletica>=30.4 && bas.massa_muscular_esqueletica<35.4)bas.status_esqueletica = 'Alto';
                if(bas.massa_muscular_esqueletica>=35.4 )bas.status_esqueletica = 'Muito Alto';
            }
            if(bas.idade>=40 && bas.idade<=59){
                if(bas.massa_muscular_esqueletica<24.1)bas.status_esqueletica = 'Baixo';
                if(bas.massa_muscular_esqueletica>=24.1 && bas.massa_muscular_esqueletica<30.2)bas.status_esqueletica = 'Normal';
                if(bas.massa_muscular_esqueletica>=30.2 && bas.massa_muscular_esqueletica<35.2)bas.status_esqueletica = 'Alto';
                if(bas.massa_muscular_esqueletica>=35.2 )bas.status_esqueletica = 'Muito Alto';
            }
            if(bas.idade>=60 && bas.idade<=80){
                if(bas.massa_muscular_esqueletica<23.9)bas.status_esqueletica = 'Baixo';
                if(bas.massa_muscular_esqueletica>=23.9 && bas.massa_muscular_esqueletica<30)bas.status_esqueletica = 'Normal';
                if(bas.massa_muscular_esqueletica>=30 && bas.massa_muscular_esqueletica<35)bas.status_esqueletica = 'Alto';
                if(bas.massa_muscular_esqueletica>=35 )bas.status_esqueletica = 'Muito Alto';
            }
        }
        if(bas.sexo=='h'){
            if(bas.idade>=18 && bas.idade<=39){
                if(bas.massa_muscular_esqueletica<33.3)bas.status_esqueletica = 'Baixo';
                if(bas.massa_muscular_esqueletica>=33.3 && bas.massa_muscular_esqueletica<39.4)bas.status_esqueletica = 'Normal';
                if(bas.massa_muscular_esqueletica>=39.4 && bas.massa_muscular_esqueletica<44.1)bas.status_esqueletica = 'Alto';
                if(bas.massa_muscular_esqueletica>=44.1 )bas.status_esqueletica = 'Muito Alto';
            }
            if(bas.idade>=40 && bas.idade<=59){
                if(bas.massa_muscular_esqueletica<33.1)bas.status_esqueletica = 'Baixo';
                if(bas.massa_muscular_esqueletica>=33.1 && bas.massa_muscular_esqueletica<39.2)bas.status_esqueletica = 'Normal';
                if(bas.massa_muscular_esqueletica>=39.2 && bas.massa_muscular_esqueletica<43.9)bas.status_esqueletica = 'Alto';
                if(bas.massa_muscular_esqueletica>=43.9 )bas.status_esqueletica = 'Muito Alto';
            }
            if(bas.idade>=60 && bas.idade<=80){
                if(bas.massa_muscular_esqueletica<32.9)bas.status_esqueletica = 'Baixo';
                if(bas.massa_muscular_esqueletica>=32.9 && bas.massa_muscular_esqueletica<39)bas.status_esqueletica = 'Normal';
                if(bas.massa_muscular_esqueletica>=39 && bas.massa_muscular_esqueletica<43.7)bas.status_esqueletica = 'Alto';
                if(bas.massa_muscular_esqueletica>=43.7 )bas.status_esqueletica = 'Muito Alto';
            }
        }

        
        if(bas.gordura_visceral>=3 && bas.gordura_visceral<=4) bas.status_visceral = 'Normal';
        if(bas.gordura_visceral>=5 && bas.gordura_visceral<=6) bas.status_visceral = 'Medio';
        if(bas.gordura_visceral>=7 && bas.gordura_visceral<=9) bas.status_visceral = 'Alto';
        if(bas.gordura_visceral>=10 && bas.gordura_visceral<=12) bas.status_visceral = 'Muito Alto';
        if(bas.gordura_visceral>12) bas.status_visceral = 'Perigo p/ Saúde';
        console.log("Calculado ",bas)
        setBase(bas);
    }

    function GraficoPizza(){
        if(!base)return <></>;
        let v = parseFloat(base.percentual_gordura_corporal)+parseFloat(base.massa_muscular_esqueletica)+parseFloat(base.massa_ossea);
        const gordura = parseFloat(((parseFloat(base.percentual_gordura_corporal)/v)*100).toFixed(2));
        const musculo = parseFloat(((parseFloat(base.massa_muscular_esqueletica )/v)*100).toFixed(2));
        let osso = 100 - parseFloat((gordura+musculo)+'');
        osso = parseFloat(osso.toFixed(2));
        return (
            <ChartPieCP series={[
                {
                    id:'gor',
                    label:`${gordura}% de Gordura`,
                    value:base.percentual_gordura_corporal
                },
                {
                    id:'gor1',
                    label:`${musculo}% Muscular`,
                    value:base.massa_muscular_esqueletica   
                },
                {
                    id:'gor2',
                    label:`${osso}% Osseo`,
                    value:base.massa_ossea
                }

            ]} loading={false} height={300}  width={300}></ChartPieCP>
        )
    }

    let html = `
   <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Bioimpedancia</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Questrial&display=swap" rel="stylesheet">
    <style>
        .titulo{
            font-size: 35px;
        }

        .bold {
            font-family: "Montserrat", sans-serif;
            font-optical-sizing: auto;
            font-weight: 400;
            font-style: normal;
        }

        .tabela{
            width: 90%;
            margin:auto;
            /* border-collapse: collapse; */
        }

        
        th, td { border: none;
            padding: 8px; 
            text-align: left; 
            padding-left:5px;
        }
        td{
            padding-left:5px;
            border-bottom: 1px solid black;
            
        }

        tr{
            line-height: 6px;
        }
        
        /* th, td, tr:first-child, tr:last-child { 
            border: 1px solid black; 
         } 
         tr > td:not(:first-child), tr > td:not(:last-child) {
             border-left: none; 
             border-right: none;
        } */

        .dia{
            width: 100%;
            text-align: right;
            font-weight: 591;
            font-size: 18px;
        }

        .lt{
            line-height: 13px;
            font-size: 12px;
            font-weight: 637;
            min-width: 100px;
        }

        .campo-t{
            min-width: 100px;
        }

        .centro{
            text-align: center;
            font-size: 12px;
        }

        .caixa-titulo-relatorio{
            display: inline-block;
            position: relative;
            bottom: 8px;
        }

        .composicao{
            width: 300px;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
            font-weight: 744;
        }

    </style>
</head>
<body>
    <section style="width: 1000px; text-align:center">
        <div style="width: 300px;max-height: 100px;overflow:hidden; display: inline-block;margin-right:45px">
            <img src=":logomarca" style="width: 100%;">
        </div>
        <div class="titulo caixa-titulo-relatorio bold" style="margin-left:60px">
            Relatório de Bioimpedância
            <div class="dia bold">
                :empresa
            </div>
            <div class="dia bold">
                Data de Avaliação: :dia #:codigo
            </div>
            <div class="dia bold">
                Avaliador: :avaliador
            </div>
        </div>
    </section>
    <table class="bold tabela" cellspacing="3px">
        <tr class="lt">
            <th style="width: 400px;">Nome</th>
            <th>Sexo</th>
            <th>Idade</th>
            <th>Peso</th>
        </tr>
        <tr>
            <td>:nome</td>
            <td>:sexo</td>
            <td>:idade</td>
            <td>:peso</td>
        </tr>
    </table>
    <table class="bold tabela" cellspacing="3px">
        <tr class="lt">
            <th class="campo-t">RM- Metabolismo em Repouso</th>
            <th class="campo-t">BMI - Indice de Massa Corporal</th>
            <th class="campo-t">Percentual de Gordura Corporal</th>
            <th class="campo-t">Percentual de Músculo Esquelético</th>
            <th class="campo-t">Gordura Visceral</th>
        </tr>
        <tr>
            <td class="centro">:rm</td>
            <td class="centro">:bmi</td>
            <td class="centro">:gordura_corporal</td>
            <td class="centro">:musculo_esqueletico</td>
            <td class="centro">:gordura_visceral</td>
        </tr>
    </table>

    <table style="margin-left:100px">
        <tr>
            <td style="border-bottom: none;" class="composicao bold"> Composição Corporal</td>
            <td style="border-bottom: none;">
                <div style="text-align: center;width: 100%;max-height: 200px;">
                <img src=":composicao" style="width: 80%;"/>
                </div>
            </td>
        </tr>
    </table>
    <br>
    <br>
    <br>
    <div class="bold" style="text-align:center;width:1000px;">
        GRAFICOS DE EVOLUÇÃO DAS ULTIMAS MEDIÇÕES
    </div>
    <br>
    <br>

    <img src=":tabela">
    <br>
    <img src=":tabela2">

    
</body>
</html>
    `;

    async function gerarPDF(){
        setCarregando(true);
        const c:any = await handleCapture(divRef.current);
        const tab:any = await handleCapture(tabelaRef.current);
        const tab2:any = await handleCapture(tabelaRef2.current);
       
        //@ts-ignore
        html = substituir(html,'logomarca', AppStateUtils.getDomainData()?.schema.logoUrl);
        html = substituir(html,'empresa', AppStateUtils.getCurrentFranchise()?.name);
        html = substituir(html,'codigo', base.id);
        html = substituir(html,'avaliador', base.avaliador);
        html = substituir(html,'nome',props.pessoa.name );
        html = substituir(html,'sexo',props.pessoa.gender == 'MALE'?'Masculino':'Feminino');
        html = substituir(html,'peso',props?.registros[0]?.peso_corporal_total+' Kg.');
        html = substituir(html,'rm',base?base.taxa_metabolismo_basal:'');
        html = substituir(html,'bmi',base?base.status_imc:'');
        html = substituir(html,'gordura_corporal',base?base.status_gordura_corporal:'');
        html = substituir(html,'musculo_esqueletico',base?base.status_esqueletica:'');
        html = substituir(html,'gordura_visceral',base?base.status_visceral:'');
        html = substituir(html,'idade',DateUtils.ageCalculate(new Date(props.pessoa.birthDate+'')) );
        html = substituir(html,'dia', DateUtils.formatDate(base.dia,DateFormatEnum.BR_WITHOUT_TIME));
        html = substituir(html,'composicao', c);
        html = substituir(html,'tabela', tab);
        html = substituir(html,'tabela2', tab2);
        console.log("A variavel ", html);

        const novo:any = {
            html:html,
            fileName:`${props.pessoa.code}_bio.pdf`,
            orientation:"PORTRAIT",
            pageSize:"A4"
        }
       
        
        const endereco = `${SystemConfig.getInstance().apiBaseUrl}/people/documento-pdf`;

        const resultado:any = await RequestHelper.runRequest({
            url: endereco,
            method: HttpMethodEnum.POST,
            params: {
                dto:novo
            }
        });
        if(resultado?.data?.data?.url){
            window.open(resultado.data.data.url,'_blank');
        }
        setCarregando(false);
    }

    

    return (
        <>
       
        <div  style={{ position: 'absolute', top: '-9999px', left: '-9999px', width: '1000px' }}>
            <table ref={tabelaRef}>
                <tr>
                    <td>
                        <ChartLineOrBarCP height='80px' 
                        xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                        ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.massa_muscular_esqueletica),name:'Indice de Massa'}]:[{data:[],name:''}]}
                        type='paulo'
                        titulo='Evolução - Massa Muscular'
                        tipoData=''
                        />
                    </td>
                    <td>
                        <ChartLineOrBarCP height='80px' 
                        xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                        ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.percentual_gordura_corporal),name:'% de Gordura'}]:[{data:[],name:''}]}
                        type='paulo'
                        titulo='Evolução - Gordura'
                        tipoData='%'
                        />
                    </td>
                    <td>
                    <ChartLineOrBarCP height='130px' 
                        xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                        ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.indice_massa_corporal),name:'IMC'}]:[{data:[],name:''}]}
                        type='paulo'
                        titulo='Evolução IMC'
                        tipoData=''
                        />
                    </td>
                </tr>
                
            </table>
        <table ref={tabelaRef2}>
            <tr>
                    <td>
                        <ChartLineOrBarCP height='80px' 
                        xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                        ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.gordura_visceral),name:'Gordura Visceral'}]:[{data:[],name:''}]}
                        type='paulo'
                        titulo='Evolução - Gordura Visceral'
                        tipoData='%'
                        />
                    </td>
                    <td>
                        <ChartLineOrBarCP height='80px' 
                        xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                        ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.massa_magra),name:'%'}]:[{data:[],name:''}]}
                        type='paulo'
                        titulo='Evolução - Massa Magra'
                        tipoData='%'
                        />
                    </td>
                    
                </tr>
            </table>
        </div>
            <Botoes>
                <ButtonCP
                    icon={'pdf'}
                    type='primary'
                    size={'small'}
                    loading={carregando}
                    onClick={gerarPDF}
                >
                    Gerar PDF
                </ButtonCP>
            </Botoes>
            <AreaBotoes>
                <StatisticCardCP
                        title={' Avaliador Responsável'}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faUserMd} size={'2x'}/>}
                        value={base?base.avaliador:''}
                        loading={false}

                    />
                <StatisticCardCP
                        title={' Data'}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faCalendar} size={'2x'}/>}
                        value={base?DateUtils.formatDate(base.dia,DateFormatEnum.BR_WITHOUT_TIME):''}
                        loading={false}

                    />


                <StatisticCardCP
                        title={' Altura do Paciente'}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faCaretSquareUp} size={'2x'}/>}
                        value={props.pessoa.altura+' cm'}
                        loading={false}

                    />
                <StatisticCardCP
                        title={' Idade do Paciente'}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faBirthdayCake} size={'2x'}/>}
                        value={DateUtils.ageCalculate(new Date(props.pessoa.birthDate+''))}
                        loading={false}

                    />
                
                <StatisticCardCP
                        title={' RM- Metabolismo em Repouso'}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faInfoCircle} size={'2x'}/>}
                        value={base?base.taxa_metabolismo_basal:''}
                        loading={false}

                    />
                <StatisticCardCP
                        title={` BMI - Indice de Massa Corporal (${base?base.indice_massa_corporal:''})`}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faMedkit} size={'2x'}/>}
                        value={base?base.status_imc:''}
                        loading={false}

                    />
                <StatisticCardCP
                        title={` Percentual de Gordura Corporal (${base?base.percentual_gordura_corporal:''})`}
                        color={ThemeAnt.primaryColor}
                        valueStyle={propriedade}
                        prefix={<FontAwsomeIconCP type={faMedkit} size={'2x'}/>}
                        value={base?base.status_gordura_corporal:''}
                        loading={false}

                    />
                <StatisticCardCP
                        title={` Percentual de Músculo Esquelético (${base?base.massa_muscular_esqueletica:''})`}
                        color={ThemeAnt.primaryColor}
                        valueStyle={propriedade}
                        prefix={<FontAwsomeIconCP type={faMedkit} size={'2x'}/>}
                        value={base?base.status_esqueletica:''}
                        loading={false}

                    />
                <StatisticCardCP
                        title={` Gordura Visceral (${base?base.gordura_visceral:''})`}
                        valueStyle={propriedade}
                        color={ThemeAnt.primaryColor}
                        prefix={<FontAwsomeIconCP type={faMedkit} size={'2x'}/>}
                        value={base?base.status_visceral:''}
                        loading={false}

                    />
               
               
            </AreaBotoes>
            

           <TabsCP
                tabPosition={'left'}
                defaultActiveKey={'delivered'}
                tabs={[
                        {
                            key: 'kdetalhe',
                            title: 'Detalhe',
                            content: (
                                <Caixa >
                                    <div className='titulo'>Composição Corporal</div>
                                    <div className="conteudo">
                                        <StatisticCardCP
                                            title={'  Peso'}
                                            color={ThemeAnt.primaryColor}
                                            prefix={<FontAwsomeIconCP type={faBalanceScale} size={'2x'}/>}
                                            value={props?.registros[0]?.peso_corporal_total+' Kg.'}
                                            loading={false}

                                        />
                                        
                                        <div ref={divRef}>
                                            <GraficoPizza/>
                                        </div>
                                    </div>
                                </Caixa>
                            )
                        },
                        {
                            key: 'kpeso',
                            title: 'Peso',
                            content: (
                                <CardCP title={'Evolução do Peso Corporal'} >
                                        <ChartLineOrBarCP
                                                    xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                    ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.peso_corporal_total),name:'Peso Corporal'}]:[{data:[],name:''}]}
                                                    type={'line'}
                                                    height={'100px'}
                                                    
                                            />
                                </CardCP>
                            )
                        },
                        {
                            key: 'kmuscular',
                            title: 'Massa',
                            content: (
                                <>
                                    <CardCP title={'Evolução do Indice de Massa Corporal (IMC)'} >
                                        <ChartLineOrBarCP
                                                    xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                    ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.indice_massa_corporal),name:'IMC'}]:[{data:[],name:''}]}
                                                    type={'line'}
                                                    height={'100px'}
                                                    
                                        />
                                    </CardCP>

                                    <CardCP title={'Evolução da Massa Muscular'} >
                                            <ChartLineOrBarCP
                                                        xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                        ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.massa_muscular_esqueletica),name:'Indice de Massa'}]:[{data:[],name:''}]}
                                                        type={'line'}
                                                        height={'100px'}
                                                        
                                                />
                                    </CardCP>

                                    <CardCP title={'Evolução da Massa Magra'} >
                                        <ChartLineOrBarCP
                                                    xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                    ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.massa_magra),name:'Indice de Massa'}]:[{data:[],name:''}]}
                                                    type={'line'}
                                                    height={'100px'}
                                                    
                                            />
                                    </CardCP>
                                    <CardCP title={'Evolução da Massa Ossea'} >
                                        <ChartLineOrBarCP
                                                    xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                    ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.massa_ossea),name:'Indice de Massa Ossea'}]:[{data:[],name:''}]}
                                                    type={'line'}
                                                    height={'100px'}
                                                    
                                            />
                                    </CardCP>
                                    
                                </>
                            )
                        },
                        {
                            key: 'kgordura',
                            title: 'Gordura',
                            content: (
                                <>
                                <CardCP title={'Evolução do Percentual de Gordura do Corpo'} >
                                        <ChartLineOrBarCP
                                                    xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                    ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.percentual_gordura_corporal),name:'% de Gordura'}]:[{data:[],name:''}]}
                                                    type={'line'}
                                                    height={'100px'}
                                                    
                                            />
                                </CardCP>
                                <CardCP title={'Evolução da Gordura Visceral'} >
                                        <ChartLineOrBarCP
                                                    xAxis={props.registros?props.registros.reverse().map((i:any)=>DateUtils.formatDate(i.dia,DateFormatEnum.BR_WITHOUT_TIME)):[]}
                                                    ySeries={props.registros?[{data:props.registros.reverse().map((i:any)=>i.gordura_visceral),name:'Indice de Gordura'}]:[{data:[],name:''}]}
                                                    type={'line'}
                                                    height={'100px'}
                                                    
                                            />
                                </CardCP>
                                </>
                            )
                        }
                    ]}
            />
        </>
    )


}

const AreaBotoes = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #e6e6e6;
    margin-bottom: 5px;
    border-radius: 4px;
    padding-top: 8px;
    justify-content:center;
`

const AvisoVazio = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    font-weight: 645;
    color: gray;
`

const Botoes = styled.div`
    display:flex;
    justify-content:flex-end;
    padding-inline:5px;
    padding-block:8px;
`

const Caixa = styled.div`
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border-radius:8px;
    padding:4px;
    margin-block: 17px;
    margin-inline: 6px;

    .titulo{
        font-weight: 645;
        text-transform: uppercase;
        text-align: center;
        border-bottom: solid 6px slategray;
        font-size: 20px;
    }
    
    .conteudo{
        display:flex;
        gap:5px;
        justify-content:center;
        align-items:center;
    }
`


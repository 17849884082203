import React, { useEffect, useState } from 'react'
import { IPersonResponseDTO } from '../../services/person/dtos/response/IPersonResponseDTO'
import styled from 'styled-components';
import { ButtonCP } from 'main/common/components/button/ButtonCP';
import { DrawerCP } from 'main/common/components/drawer/DrawerCP';
import { InputCP } from 'main/common/components/form-fields/input/InputCP';
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum';
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils';
import { UtilGeral } from '../../utils/UtilGeral';
import { AppStateUtils } from 'main/common/utils/AppStateUtils';
import { RequestHelper } from 'main/common/request-manager/RequestHelper';
import { SystemConfig } from 'main/config/SystemConfig';
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum';
import { NotificationHelper } from 'main/common/helpers/NotificationHelper';
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum';
import { TableCP } from 'main/common/components/table/TableCP';
import { FlexCP } from 'main/common/components/flex/FlexCP';
import { TabsCP } from 'main/common/components/tabs/TabsCP';
import { Dashbio } from './Dashbio';
import { PopConfirmCP } from 'main/common/components/pop-confirm/PopConfirmCP';

interface BioInterface{
    person:IPersonResponseDTO,
    objeto?:any
}




export function Bioimpedancia(props: BioInterface): JSX.Element {
    const [isPainelShow,setIsPainelShow] = useState(false);
    const [registro,setRegistro] = useState<any>({});
    const [carregando,setCarregando] = useState(false);
    const [registros,setRegistros] = useState<any>([]);
    useEffect(()=>{carregarRegistros()},[]);
    


    if(!props.person.altura) return (<Aviso>Complete o cadastro do paciente (Informar altura)</Aviso>);
    if(!props.person.birthDate) return (<Aviso>Complete o cadastro do paciente (Informar nascimento)</Aviso>);
    if(!props.person.gender || !'MALE,FEMALE'.includes(props.person.gender)) return (<Aviso>Complete o cadastro do paciente (Informar o sexo biológico)</Aviso>);

    const colunas = [
        {
          title: '# ',
          dataIndex: 'id',
          key: 'id'
          
        }, 
        {
          title: 'Dia ',
          dataIndex: 'dia',
          key: 'dia',
          render:(txt:any,dados:any)=> DateUtils.formatDate(dados.dia,DateFormatEnum.BR_WITHOUT_TIME)
        }, 
        {
            title: 'Peso Corporal ',
            dataIndex: 'peso_corporal_total',
            key: 'peso_corporal_total',
            render:(txt:any,dados:any)=> dados.peso_corporal_total+' (Kg)'
          } ,
        {
            title: 'IMC ',
            dataIndex: 'indice_massa_corporal',
            key: 'indice_massa_corporal',
            render:(txt:any,dados:any)=> dados.indice_massa_corporal
          } ,
        {
          title: 'Avaliador ',
          dataIndex: 'avaliador',
          key: 'avaliador',
          render:(txt:any,dados:any)=> dados.avaliador
        } ,
        {
            key: 'actions',
            render: (text:any, item:any) => (
                <FlexCP justifyContent={'space-evenly'} >
                    <ButtonCP
                        type={'primary'}
                        icon={'edit'}
                        size={'small'}
                        onClick={() => {
                            let reg = UtilGeral.CLONE(UtilGeral.objetoPontoPorVirgula(item));
                            reg.dia = DateUtils.formatDate(reg.dia,DateFormatEnum.BR_WITHOUT_TIME);
                            console.log("O item para edição ", reg);
                            setRegistro(reg);
                            setIsPainelShow(true);
                        }}
                    />
                    <PopConfirmCP
                        placement='top'
                        title={'Você tem certeza que deseja excluir este registro?'}
                        onConfirm={()=>{excluirRegistro(item)}}
                        >
                        <ButtonCP
                            type={'primary'}
                            icon={'delete'}
                            size={'small'}
                            onClick={() => {
                                
                            }}
                        />
                    </PopConfirmCP>
                </FlexCP>
            )
        }
    ]

    let timer:any;
    function atualizarRegistro(){
        setRegistro(UtilGeral.CLONE(registro));
    }

    function mudou(ev:any,campo:any){
        registro[campo] = ev;
        if(timer)clearTimeout(timer);
        timer = setTimeout(()=>{
            atualizarRegistro();
        }, 530);
    }


    async function excluirRegistro(item:any){
        console.log("Excluindo registro ", item);
        setCarregando(true);
        setRegistros([]);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/people/excluir-registro-bioimpedancia`,
            method: HttpMethodEnum.POST,
            params: {
                corpo:{idRegistro:item.id},
                schema:AppStateUtils.getDomainSlug()
            }
        }).finally(()=>{setCarregando(false)}); 
        if(resultado?.data?.data=='ok'){
            carregarRegistros();
            NotificationHelper.success('Sucesso',`Registro #${item.id} removido com sucesso!`);
        }
    }


    async function carregarRegistros(){
        setCarregando(true);
        setRegistros([]);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/people/obter-registros`,
            method: HttpMethodEnum.POST,
            params: {
                corpo:{id_paciente:props.person.code},
                schema:AppStateUtils.getDomainSlug()
            }
        }).finally(()=>{setCarregando(false)}); 
        if(resultado?.data?.data)setRegistros(resultado.data.data);
        setCarregando(false);
        console.log("RESULTADO ", resultado, registros, setRegistros,carregando)
    }

    async function salvarRegistro(registro:any){
        const novo = UtilGeral.objetoVirgulaPorPonto(UtilGeral.CLONE(registro));
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/people/salvar-registro`,
            method: HttpMethodEnum.POST,
            params: {
                corpo:novo,
                schema:AppStateUtils.getDomainSlug()
            }
        }).finally(()=>{}); 
        console.log("Salvou ", resultado);
        if(resultado.status==201){
            NotificationHelper.success('Sucesso','Registro de BioImpedância registrado com sucesso!');
            setIsPainelShow(false);
            carregarRegistros();
            return;
        }

        NotificationHelper.error('Erro','Houve algum problema ao tentar salvar o registro');
    }

    function validarESalvar(){
        console.log("Salvando ", registro);
        const valido = UtilGeral.VALIDAR_OBJETO(registro,
            ['dia','peso_corporal_total','percentual_gordura_corporal','massa_magra','massa_muscular_esqueletica',
                'gordura_visceral','massa_ossea','agua_corporal_total','taxa_metabolismo_basal','indice_massa_corporal',
                'relacao_cintura_quadril'
            ],
            ['INFORME A DATA','INFORME O PESO CORPORAL','INFORME O PERCENTUAL DE GORDURA CORPORAL','INFORME A MASSA MAGRA','INFORME A MASSA MUSCULAR ESQUELETICA',
                'INFORME A GORDURA VISCERAL','INFORME A MASSA OSSEA','INFORME A AGUA CORPORAL TOTAL','INFORME A TAXA DE METABOLISMO BASAL','INFORME O INDICE DE MASSA CORPORAL',
                'INFORME A RELACAO DA CINTURA X QUADRIL'
            ]);

        if(valido){
            //fazer o CRUD
            const novo = UtilGeral.CLONE(registro);
            novo.dia = UtilGeral.DATA_SQL_DE_DATA_FORMATADA(novo.dia);
            novo.idade = UtilGeral.DEVOLVER_SO_NUMEROS(DateUtils.ageCalculate(new Date(props.person.birthDate+'')));
            novo.id_paciente = props.person.code;
            novo.id_avaliador = AppStateUtils.getLoggedUserData()?.user.code;
            console.log("Registro pronto ", novo);

            salvarRegistro(novo)
        }
    }
    
    return (
        <>
            <TabsCP
                        tabPosition={'top'}
                        defaultActiveKey={'delivered'}
                        tabs={
                            [
                                {
                                    key: 'princ',
                                    title: 'DashBoard',
                                    content: (
                                       <Dashbio pessoa={props.person} registros={registros} />
                                    )
                                },
                                {
                                    key: 'crud',
                                    title: 'Registros',
                                    content: (
                                        <>
                                            <RolBotao>
                                                <ButtonCP
                                                    icon={'plus'}
                                                    type={'primary'}
                                                    onClick={() => {
                                                        setRegistro({id:-1,dia:DateUtils.hoje()});
                                                        setIsPainelShow(true);
                                                    }}
                                                    // loading={getPersonContactsRequest.isAwaiting}
                                                    marginLeft={20}
                                                    size={'small'}
                                                    >
                                                    {'Novo Registro'}
                                                </ButtonCP>

                                            </RolBotao>

                                            <TableCP
                                                columns={colunas}
                                                data={registros ?? []}
                                                loading={carregando}
                                                
                                            />
                                        </>
                                    )
                                },
                            ]
                               
                        }
                    />

            


            <DrawerCP
                title={`Novo Registro de Bioimpedância (${props.person.name})`}
                visible={isPainelShow}
                closable={true}
                onClose={()=>setIsPainelShow(false)}
                width={500}
                footer={(
                    <ButtonCP
                        icon={'save'}
                        type='primary'
                        size={'small'}
                        onClick={validarESalvar}
                    >
                        Salvar Registro
                    </ButtonCP>
                )}
                >
                    <InputCP<any>
                    label={'Data'}
                    fieldName={'dia'}
                    onChange={(ev:any)=>mudou(ev,'dia')}
                    value={registro.dia}
                    mask={InputMaskTypeEnum.DATE}
                    />

                    <DuasColunas>

                        <InputCP<any>
                        label={'Peso Atual'}
                        fieldName={'peso_corporal_total'}
                        value={registro.peso_corporal_total}
                        onChange={(ev:any)=>mudou(ev,'peso_corporal_total')}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Percentual de Gordura Total'}
                        fieldName={'percentual_gordura_corporal'}
                        onChange={(ev:any)=>mudou(ev,'percentual_gordura_corporal')}
                        value={registro.percentual_gordura_corporal}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Massa Magra'}
                        fieldName={'massa_magra'}
                        onChange={(ev:any)=>mudou(ev,'massa_magra')}
                        value={registro.massa_magra}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Massa Muscular Esquelética'}
                        fieldName={'massa_muscular_esqueletica'}
                        onChange={(ev:any)=>mudou(ev,'massa_muscular_esqueletica')}
                        value={registro.massa_muscular_esqueletica}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Gordura Visceral'}
                        fieldName={'gordura_visceral'}
                        onChange={(ev:any)=>mudou(ev,'gordura_visceral')}
                        value={registro.gordura_visceral}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Massa Ossea'}
                        fieldName={'massa_ossea'}
                        onChange={(ev:any)=>mudou(ev,'massa_ossea')}
                        value={registro.massa_ossea}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Agua Corporal Total'}
                        fieldName={'agua_corporal_total'}
                        onChange={(ev:any)=>mudou(ev,'agua_corporal_total')}
                        value={registro.agua_corporal_total}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Taxa de Metabolismo Basal'}
                        onChange={(ev:any)=>mudou(ev,'taxa_metabolismo_basal')}
                        fieldName={'taxa_metabolismo_basal'}
                        value={registro.taxa_metabolismo_basal}
                        mask={InputMaskTypeEnum.MONEY}
                        />

                        <InputCP<any>
                        label={'Indice de Massa Corporal'}
                        fieldName={'indice_massa_corporal'}
                        onChange={(ev:any)=>mudou(ev,'indice_massa_corporal')}
                        value={registro.indice_massa_corporal}
                        mask={InputMaskTypeEnum.MONEY}
                        />
                        <InputCP<any>
                        label={'Relação Cintura/Quadril'}
                        fieldName={'relacao_cintura_quadril'}
                        onChange={(ev:any)=>mudou(ev,'relacao_cintura_quadril')}
                        value={registro.relacao_cintura_quadril}
                        mask={InputMaskTypeEnum.MONEY}
                        />
                    </DuasColunas>

                    

            </DrawerCP>

            
                    
        </>
    )




}


const Aviso = styled.div`
    font-weight: 729;
    color: #df4e4e;
    font-size: 12px;
    text-transform: uppercase;
`

const DuasColunas = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 11px;
`

const RolBotao = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-block: 12px;
`


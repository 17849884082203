import { Layout } from 'antd'
import styled, { css } from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { HeaderTitleICP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/inner/HeaderTitleICP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'

interface WrapperSCPProps {
    noBackground?: boolean,
    titleUnderlineColor?: string,
    titleFlexProp?: number,
    bgColor?: string,
}

interface ICPProps extends WrapperSCPProps {
    title?: string | JSX.Element,
    children?: React.ReactNode
    middleContent?: JSX.Element
    onClickBack?: () => void,
    arrowBack?: boolean,
    titleSize?: FontSizeTP,
    style?: React.CSSProperties,
    color?: string
}

/**
 * Componente de cabeçalho do sistema.
 */
export function HeaderCP(props: ICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <>
            <WrapperSCP
                isSmallScreen={screenSize.xs || screenSize.sm}
                bgColor={props.bgColor}
                noBackground={props.noBackground}
                titleUnderlineColor={props.titleUnderlineColor}
            >
                <Layout.Header style={props.style}>
                    <LeftContainerSCP titleFlexProp={props.titleFlexProp}>
                        {
                            props.arrowBack &&
                            <ButtonCP
                                onClick={props.onClickBack}
                                ghost={true}
                                type={'primary'}
                                shape={'circle'}
                                icon={'arrow-left'}
                                marginRight={10}
                            />
                        }

                        {
                            !!props.title &&
                            <HeaderTitleICP
                                color={props.color ?? ThemeProject.headerFontColor}
                                title={props.title}
                                noBackground={props.noBackground}
                                titleSize={screenSize.smd ? 'large' : props.titleSize}
                            />
                        }
                    </LeftContainerSCP>

                    {props.middleContent}

                    <RightContentSCP>
                        {props.children}
                    </RightContentSCP>

                </Layout.Header>
            </WrapperSCP>
        </>
    )
}

const underlineCSS = css<{ titleUnderlineColor?: string }>`
    ::after{
        content: '';
        width: 58px;
        height: 4px;
        position: absolute;
        background: ${props => props.titleUnderlineColor ?? ThemeProject.titleBarColor};
        bottom: 0;
        left: 25px;
    }
`

const WrapperSCP = styled.div<WrapperSCPProps & { isSmallScreen: boolean }>`

    .ant-layout-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 ${props => props.isSmallScreen ? 40 : 25}px;
        line-height: normal;
        background: ${props => props.bgColor ?? (!!props.noBackground ? 'transparent' : ThemeProject.headerBackgroundColor)};
        height: 65px;
        //max-width: calc(100vw - 15%);
    }

    ${props => (!props.noBackground ? 'position: relative;' : '')}
    ${props => (!props.noBackground ? underlineCSS : '')}
`

const LeftContainerSCP = styled.div<{ titleFlexProp?: number }>`
    display: flex;
    justify-content: space-between;
    flex: ${props => props.titleFlexProp ?? 'unset'};
`

const RightContentSCP = styled.div`
    display: flex;
    justify-content: flex-end;
`

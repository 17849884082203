import { ThemeAnt } from 'config/theme/ant/ThemeAnt'

/**
 * Objeto de parametrizacao para componente de grafico de barras generico.
 */
export const BAR_CHART_CONFIG_OPTIONS = {
    chart: {
        type: 'bar',
        height: 350,
        width: '100%',
        stacked: false,
        toolbar: {
            show: true,
            tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: []
            }
        }
    },
    plotOptions: {
        bar: {
            endingShape: 'flat',
            columnWidth: '90%',
            dataLabels: {
                position: 'top',
                maxItems: 100,
                hideOverflowingLabels: true,
            },
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: 2,
        show: false
    },
    series: [],
    colors: [ThemeAnt.primaryColor],
    xaxis: {
        type: 'category',
        axisBorder: {
            show: true
        },
        lines: {
            show: true,
        },
        labels: {
            rotate: -45,
            rotateAlways: false,
            minHeight: 60
        },
    },
    yaxis: {
        min: 0,
        max: (max: any) => max,
        forceNiceScale: true,
        labels: {
            offsetY: -5
        },
    },
}

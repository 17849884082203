import React, { useEffect, useState } from 'react'
import { FranchiseFormModel } from 'main/modules/admin/components/franchise-data-screen-content/inner/FranchiseFormModel'
import { useFormStateManager } from 'main/common/form-state-manager/UseFormStateManager'
import { RowCP } from 'main/common/components/grid/RowCP'
import { ColumnCP } from 'main/common/components/grid/ColumnCP'
import { InputCP } from 'main/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'main/common/enums/InputMaskTypeEnum'
import { AddressPanelCP } from 'main/modules/people/components/address-panel/AddressPanelCP'
import styled from 'styled-components'
import { IFranchiseResponseDTO } from 'main/modules/admin/services/franchise/dtos/response/IFranchiseReponseDTO'
import { FranchiseFormUtils } from 'main/modules/admin/components/franchise-data-screen-content/inner/FranchiseFormUtils'
import { CardCP } from 'main/common/components/card/CardCP'
import { IFranchiseSaveRequestDTO } from 'main/modules/admin/services/franchise/dtos/request/IFranchiseSaveRequestDTO'
import { ButtonCP } from 'main/common/components/button/ButtonCP'
import { Checkbox } from "antd";
import { RequestHelper } from 'main/common/request-manager/RequestHelper'
import { SystemConfig } from 'main/config/SystemConfig'
import { AppStateUtils } from 'main/common/utils/AppStateUtils'
import { HttpMethodEnum } from 'main/common/enums/HttpMethodEnum'

interface IFranchiseDataScreenContentCPProps {
    franchise: IFranchiseResponseDTO
    onUpdateFranchise: (_franchise: IFranchiseSaveRequestDTO) => void
    loading: boolean
}

/**
 * Dados cadastrais da franquia
 */
export function FranchiseDataScreenContentCP(props: IFranchiseDataScreenContentCPProps): JSX.Element {

    const [formValidator, setFormValidator] = useState<FranchiseFormModel>()
    const formStateManager = useFormStateManager<FranchiseFormModel>(formValidator)
    const [carregando,setCarregando] = useState<any>(false);
    const [parametros, setParametros] = useState<any>({});
    useEffect(init, [])

    async function carregarParametros(){
        console.log("Carregando tela");
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/franchises/carregar-parametros`,
            method: HttpMethodEnum.POST,
            params: {
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>{setCarregando(false)});
        console.log("Resultado ", resultado);
        const ok = !!resultado?.data?.data?.parametros;
        if(ok){
            const para = resultado.data.data.parametros;
            setParametros(para);
            console.log("PArAMETROS SETADOS ", para);
        }
        setCarregando(false);
    }

    async function atualizarParametros(valor:any,campo:any){
        setCarregando(true);
        const resultado:any = await RequestHelper.runRequest({
            url: `${SystemConfig.getInstance().apiBaseUrl}/franchises/salvar-preferencias`,
            method: HttpMethodEnum.POST,
            params: {
                campo:campo,
                valor:valor,
                codigoFranquia:AppStateUtils.getCurrentFranchise()?.code,
                schema:AppStateUtils.getDomainSlug()
            }
           
        }).finally(()=>{setCarregando(false)});
        setCarregando(false);
        console.log("O resultado ", resultado); 
        setParametros(resultado.data.data.parametros);
    }

    /**
     * Inicializa a tela.
     */
    function init(): void {
        setFormValidator(new FranchiseFormModel(FranchiseFormUtils.getFormData(props.franchise)))
        carregarParametros();
    }

    /**
     * Ao clicar em salvar.
     */
    async function save(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        const formValues = formStateManager.getFormValues()

        if (!await formStateManager.validate() || !formValues)
            return

        props.onUpdateFranchise(FranchiseFormUtils.getDto(formValues))
    }

    return (
        <>
            <CardCP title={'Dados Básicos'}>
                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={6}>
                        <InputCP
                            fieldName={'cnpj'}
                            formStateManager={formStateManager}
                            mask={InputMaskTypeEnum.CNPJ}
                            label={'CNPJ'}
                            required={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={6}>
                        <InputCP
                            fieldName={'stateRegistration'}
                            formStateManager={formStateManager}
                            label={'Inscrição municipal'}
                        />
                    </ColumnCP>
                </RowCP>
                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={12}>
                        <InputCP
                            fieldName={'socialName'}
                            formStateManager={formStateManager}
                            label={'Razão Social '}
                            required={true}
                        />
                    </ColumnCP>
                    <ColumnCP size={12}>
                        <InputCP
                            fieldName={'fantasyName'}
                            formStateManager={formStateManager}
                            label={'Nome Fantasia'}
                            required={true}
                        />
                    </ColumnCP>
                </RowCP>

                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={12}>
                        <InputCP
                            fieldName={'email'}
                            formStateManager={formStateManager}
                            label={'E-mail'}
                        />
                    </ColumnCP>
                    <ColumnCP size={6}>
                        <InputCP
                            fieldName={'phone'}
                            formStateManager={formStateManager}
                            label={'Telefone'}
                            mask={InputMaskTypeEnum.PHONE}
                        />
                    </ColumnCP>
                </RowCP>
            </CardCP>
            <CardCP title={'Endereço'}>
                <AddressPanelCP formStateManager={formStateManager}/>
            </CardCP>

            <CardCP title={'Parâmetros de Unidade'} isLoading={carregando}>
                <ColumnCP size={12}>
                    <Checkbox onChange={(e:any)=>{
                        atualizarParametros(e.target.checked,'ocultarProcedimentos')
                    }} checked={!!parametros?.businessRulesConfig?.sale?.ocultarProcedimentosNFE}  >
                        Ocultar nome dos procedimentos na NFE.
                    </Checkbox>
                </ColumnCP>
            </CardCP>

            <BtnContainerSCP>
                <ButtonCP
                    type={'primary'}
                    onClick={save}
                    loading={props.loading}
                >
                    Salvar
                </ButtonCP>
            </BtnContainerSCP>
        </>
    )
}

const BtnContainerSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 20px 40px 20px;
`
